import React, { useState } from "react"
import PropTypes from "prop-types"
import { Input, FormGroup } from "reactstrap"
import { StyledUploader } from "./StyledUploader"
import { getBase64 } from "./validation"

const FileUploader = ({
  onFileSelectSuccess,
  onFileSelectError,
  fieldName,
  fieldTranslation,
  errorMessage
}) => {

  const [imageResult, setImageResult] = useState();
  const handleFileInput = async (e) => {
    // handle validations
    e.preventDefault();
    if (
      checkMimeType(e, onFileSelectError) &&
      checkFileSize(e, onFileSelectError)
    ) {
      // read the file and set the <img> tag's src
      const result = await getBase64(e.target.files[0]);
      setImageResult(result);

      // store file data in state of parent Form component
      onFileSelectSuccess(e)

    } else {
      onFileSelectError({ error: "A fájl mérete nem haladhatja meg a 6MB-ot." })
    }
  }

  // Validation

  // check extensions
  const checkMimeType = (event, onFileSelectError) => {
    //getting file object
    let files = event.target.files
    //define message container
    let err = ""
    // list allow mime type
    const types = ["image/png", "image/jpeg", "image/gif"]
    // loop access array
    for (var x = 0; x < files.length; x++) {
      // compare file type find doesn't matach
      if (types.every(type => files[x].type !== type)) {
        // create error message and assign to container
        err +=
          files[x].type +
          " nem támogatott formátum. Kérlek, használj png, jpg/jpeg vagy gif formátumot.\n"
      }
    }

    if (err !== "") {
      // if message not same old that mean has error
      event.target.value = null // discard selected file
      console.log('FileUploader Error', err)
      onFileSelectError({ error: err })
      return false
    }
    return true
  }

  // check max. file size
  const checkFileSize = (event, onFileSelectError) => {
    let files = event.target.files
    let size = 6000
    let err = ""
    for (var x = 0; x < files.length; x++) {
      // divide by 1024 to get file zise in kbytes
      if (files[x].size / 1024 > size) {
        err += files[x].type + " túl nagy fájl. Maximum fájlméret: 6MB.\n"
      }
    }
    if (err !== "") {
      event.target.value = null
      console.log('FileUploader Error - FileSize Check', err)
      onFileSelectError({ error: err })
      return false
    }

    return true
  }

  return (
    <StyledUploader
      fieldTranslation={fieldTranslation}
      className="file-uploader"
    >
      {
        imageResult === undefined
        ?
        <>
        <FormGroup className="files" id={`files-${fieldName}`} >
          <Input
            type="file"
            name={fieldName}
            id={fieldName}
            onChange={handleFileInput}
          />
        </FormGroup>
        {errorMessage ? <span className="text-danger d-block">
                      {errorMessage}
                    </span> : ''}
        </>
        :
        <img
          id={`output-${fieldName}`}
          src={imageResult}
        />
      }
      
    </StyledUploader>
  )
}

FileUploader.propTypes = {
  fieldName: PropTypes.string.isRequired,
  onFileSelectSuccess: PropTypes.func.isRequired,
  onFileSelectError: PropTypes.func.isRequired,
  fieldTranslation: PropTypes.string.isRequired,
  errorMessage: PropTypes.string
}

export default FileUploader
