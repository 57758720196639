import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import { getImage } from "gatsby-plugin-image"
import SanityBlockContent from "@sanity/block-content-to-react"
import { sanitySerializers, sanityClientConfig } from "../utils/sanityConfig"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HomeBackground from "../components/Visuals/homeBackground"
import JumboComponent from "../components/JumboComponent"
import HomeContactComponent from "../components/HomeContactComponent"
import { MediaQueries } from "../utils/responsive"
import MyForm2 from "../components/Form2"

const StyledContainer = styled(Container)`
  .content {
    margin: ${props => `${props.theme.margins.verticalMobile * 3}px auto`};
    @media ${MediaQueries.mobile} {
      margin-top: ${props => `${props.theme.margins.verticalDesktop / 2}px`};
      margin-bottom: ${props => `${props.theme.margins.verticalDesktop}px`};
    }
  }
  .textDiv {
    h1 {
      color: ${props => props.theme.colors.pink};
      font-size: 24px;
    }
    .highlight {
      color: ${props => props.theme.colors.pink};
    }
  }
`

const FormPage = props => {
  const { t, i18n } = useTranslation("common")
  const language = i18n.language
  const mainPageData = props.data.sanityHomePage
  const thisPageData = props.data.sanityFormPage

  const seoImage = getImage(thisPageData.image)?.images.fallback.src
  return (
    <Layout alternateLink={props.alternateLinks}>
      <HomeBackground />
      <StyledContainer>
        <Seo
          title={"Cseriti - Leadóknak szóló űrlap"}
          lang={language}
          image={seoImage}
        />
        <JumboComponent
          images={thisPageData.image}
          slogan={thisPageData._rawTitle[language]}
          singleImage={true}
        />
        <Row className="content justify-content-center">
          <Col xs={12} md={8} lg={6}>
            <div className="textDiv">
              <SanityBlockContent
                key={(Math.random() * 1000 + 12).toString()}
                blocks={thisPageData._rawTitleOne[language]}
                projectId={sanityClientConfig.sanity.projectId}
                dataset={sanityClientConfig.sanity.dataset}
                imageOptions={{ w: 200, h: 100, fit: "max" }}
                serializers={sanitySerializers}
              />

              <SanityBlockContent
                key={(Math.random() * 1000 + 12).toString()}
                blocks={thisPageData._rawDescriptionOne[language]}
                projectId={sanityClientConfig.sanity.projectId}
                dataset={sanityClientConfig.sanity.dataset}
                imageOptions={{ w: 200, h: 100, fit: "max" }}
                serializers={sanitySerializers}
              />
              <span
                style={{
                  display: "block",
                  textTransform: "uppercase",
                  fontSize: "2rem",
                  fontFamily: "Anton",
                  marginTop: "4rem",
                }}
              >
                {t("common:form.header")}
              </span>
            </div>
          </Col>
          <MyForm2 />
        </Row>
      </StyledContainer>
      <HomeContactComponent intro={mainPageData._rawIntro[language]} />
    </Layout>
  )
}

export const query = graphql`
  query FormPageQuery {
    sanityHomePage(_id: { eq: "d653ce08-c183-4893-a241-7b5d7d931ee6" }) {
      id
      _rawIntro
      mainImages {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      _rawSlogan
    }
    sanityFormPage {
      _rawTitle
      _rawTitleOne
      image {
        asset {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
      _rawDescriptionOne
      _id
    }
  }
`
export default FormPage
